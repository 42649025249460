.ProfileImgContainer {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  // border: 1px solid red;
  overflow: hidden;
  img {
    height: 100%;

    object-fit: cover;
  }
}

.singleImage {
  // max-height: 50vh;

  //   border: 1px solid red;
  overflow: hidden;
  border-radius: 12px;
  img {
    width: 100%;
    // max-height: 100%;
    object-fit: cover;
  }
}
