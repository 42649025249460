.container {
  height: 16vh;

  .logoContainer {
    height: 150px;
    width: 200px;
    // border: 1px solid red;
    img {
      // height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
