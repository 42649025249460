.imageContainer {
  height: 60vh;
  overflow: hidden;
  border-radius: 20px;
  img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
}

.imageForArtist {
  height: 35vh;
  //   border: 1px solid red;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100%;
    object-fit: fill;
    // border: 1px solid red;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 35px;
    border-top-right-radius: 40px;
  }
}

.artistBtn {
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
}
