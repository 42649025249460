@use "../../scss/variables.scss" as vars;

.menuContainer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  opacity: 0.97;
  z-index: 2;

  background-color: black;
  overflow: hidden;

  .menuPage {
    // border: 1px solid red;
    // width: 90%;
    height: 77%;
    .LauraHalf {
      height: 60vh;
      // border: 1px solid blue;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: vars.$color-light-gray;
      opacity: 0.85;
      border-radius: 20px;

      .imageContainerLaura {
        // border: 1px solid red;
        height: 250px;

        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          max-width: 400px;

          object-fit: cover;
        }
      }
    }
    .GianniHalf {
      height: 60vh;
      border: 1px solid white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      border-radius: 20px;
      margin-bottom: 20px;
      margin-top: 20px;

      .imageContainerGianni {
        // border: 1px solid red;

        img {
          object-fit: cover;
        }
      }
    }
  }
}
